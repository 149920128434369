import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";

function Abono() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroEstado, setFiltroEstado] = useState(""); // Nuevo estado para el filtro de estado
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10); 
  const [show, setShow] = useState(false); 
  const [resultado, setResultado] = useState(null);
  const [error, setError] = useState(null);
  const [codigoSeleccionado, setCodigoSeleccionado] = useState(null);
  const handleClose = () => setShow(false);
  const { token, user } = useSelector(state => state.auth);  

  const handleShow = (codigo) => {
    setCodigoSeleccionado(codigo);
    setShow(true);
  };
  
  const handleAccept = async() => {
    console.log('Código:', codigoSeleccionado); 
    const idusuarioenvia = user.id_usuario 

    const token = "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY";
    const codigoUsuario = "2186";
    const userNickName = "pierril";
    const metodo = "ValidarAbono";
    const datosRecibidos = [{ acov_tipo: "1", acov_codigo: codigoSeleccionado, acov_usuario: idusuarioenvia }];
    
    const data = {
      Token: token,
      User: {
        Codigo: codigoUsuario,
        UserNickName: userNickName
      },
      Metodo: {
        NombreMetodo: metodo,
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    }; 

    try {
      const response = await fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) 


      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const resultadoAPI = await response.json();
      setResultado(resultadoAPI);
      console.log('Respuesta de la API:', resultadoAPI);
    } catch (error) {
      console.error('Error en la petición:', error);
      setError(error.message);
    }

    handleClose();
  };
  
  const fetchData = () => { 
    const idusuarioenvia = String(user.id_usuario); 
  const nameuser = user.usuario ;
    console.log(nameuser); 
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Usr: {
        Codigo: idusuarioenvia,
        UserNickName: nameuser
      },
      DatosMetodo: {
        NombreMetodo: "ListaAbonos",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    }; 

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`; 

    fetch(fetchDataUrl)
    .then(response => response.json() )
    .then(data => {
      if (data && data.datos_Generados) { 
        console.log(data)
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        if (datosGeneradosDecodificados && datosGeneradosDecodificados.LISTABONO) { 
          setDatosGenerados(datosGeneradosDecodificados.LISTABONO); 
        } else {
          setDatosGenerados([]);
        }
      } else {
        setDatosGenerados([]);
      }
    })
    .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    const estadoMatch = filtroEstado ? item.Proceso === filtroEstado : true; // Aplica filtro de estado
    return (
      estadoMatch &&
      (
        item.OV.toLowerCase().includes(searchValue) ||
        item.Banco.toLowerCase().includes(searchValue) ||
        item.FormaPago.toLowerCase().includes(searchValue) || 
        item.CodigoCliente.toLowerCase().includes(searchValue) || 
        item.NombreCliente.toLowerCase().includes(searchValue)
      )
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  const descargarImagen = (base64, extension, nombreArchivo) => {
    const imagenBase64Completa = `data:image/${extension};base64,${base64}`;
    const enlace = document.createElement('a');
    enlace.href = imagenBase64Completa;
    enlace.download = `${nombreArchivo}.${extension}`;
    enlace.click();
  };

  return (
    <div> 
      <h1>Validar Abono</h1>
      <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <FormControl variant="outlined" style={{ width: '200px', marginRight: '10px' }}>
          <InputLabel>Estado</InputLabel>
          <Select
            value={filtroEstado}
            onChange={(e) => setFiltroEstado(e.target.value)}
            label="Estado"
          >
            <MenuItem value="Pendiente de Validar">Pendiente de Validar</MenuItem>
            <MenuItem value="Validado">Validado</MenuItem>
            <MenuItem value="">Todos</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleSearch} style={{ height: '55px', width: '100px' }}>
          Buscar
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow> 
            <TableCell>Código Abono</TableCell>
            <TableCell>Tipo de Abono</TableCell>
            <TableCell>Banco</TableCell>
            <TableCell>Forma de Pago</TableCell>
            <TableCell>Orden de Venta</TableCell> 
            <TableCell>Código Cliente</TableCell> 
            <TableCell>Nombre Clienta</TableCell>
            <TableCell>Número de Referencia</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Proceso</TableCell>
            <TableCell>Validar</TableCell>
            <TableCell>Descargar Imagen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map(item => (
            <TableRow key={item.acov_codigo}>
              <TableCell>{item.acov_codigo}</TableCell>
              <TableCell>{item.TipoAbono}</TableCell>
              <TableCell>{item.Banco}</TableCell>
              <TableCell>{item.FormaPago}</TableCell>
              <TableCell>{item.OV}</TableCell> 
              <TableCell>{item.CodigoCliente}</TableCell> 
              <TableCell>{item.NombreCliente}</TableCell>
              <TableCell>{item.NoReferencia}</TableCell>
              <TableCell>{item.Monto}</TableCell>
              <TableCell>{item.Fecha}</TableCell>
              <TableCell>{item.Proceso}</TableCell>
              <TableCell>
                    <Button
                      onClick={() => handleShow(item.acov_codigo)}
                      color="primary"
                      startIcon={<FontAwesomeIcon icon={faEdit} />}
                      disabled={item.Proceso === "Validado"} // Aquí se coloca el disabled en el Button
                    >
                      Validar
                    </Button>
                  </TableCell>
              <TableCell>
                <Button
                  onClick={() => descargarImagen(item.ImagenBase64, item.B64Extension, item.NombreArchivo)}
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={faDownload} />}
                >
                  Descargar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación de Validación</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de que desea validar el abono {codigoSeleccionado}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Abono;
